import type { FormControlData, FormControlValue, CreateFormControlProps } from "../SHARED/_model";
import type { ICollectionSelect } from "./_model";
import { createFormControl } from "solid-forms";
import Select from "./main";
import { Error } from "../error";
import { Label } from "../label";

export default function createSelect<V extends FormControlValue, D extends FormControlData = FormControlData>(
  ...args: CreateFormControlProps<V, D>
): ICollectionSelect<V, D> {
  const control = createFormControl<V, D>(...args);
  const name = String(control.id);
  return {
    control,
    name,
    Label: (props) => <Label control={control} for={name} {...props} />,
    Error: (props) => <Error control={control} {...props} />,
    Select: (props) => <Select control={control} name={name} {...props} />,
  };
}
